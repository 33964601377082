import ApiService from "@/core/services/ApiService";

const ADD_USEFUL_TRAINING = "usefultraining/add-usefultraining/";
const GET_USEFUL_TRAININGS = "usefultraining/get-usefultrainings";
const UPDATE_USEFUL_TRAINING = "usefultraining/update-usefultraining/<pk>/";
const DELETE_USEFUL_TRAINING = "usefultraining/delete-usefultraining/<pk>/";

export default {
    addUsefulTraining(action) {
        ApiService.setHeader();
        return ApiService.post(ADD_USEFUL_TRAINING, action);
    },
    updateUsefulTraining(action_id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_USEFUL_TRAINING.replace("<pk>", action_id), payload);
    },
    deleteUsefulTraining(action_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_USEFUL_TRAINING.replace("<pk>", action_id));
    },
    getUsefulTraining() {
        ApiService.setHeader();
        return ApiService.get(GET_USEFUL_TRAININGS).then((response) => {
            return response.data;
        });
    },
};
