
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import apiUsefulTraining from "@/core/services/UsefulTraining";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "usefultraining",
    components: {},
    data: function () {
        return {
            usefultrainings: [],
        };
    },
    methods: {
        getUsefulTraining() {
            apiUsefulTraining.getUsefulTraining().then((response) => {
                this.usefultrainings = response.data.usefultrainings;
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.usefulTraining.title"), []);

        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.usefulTraining.title"), []);
        },
    },
    created() {
        this.getUsefulTraining();
    },
});
